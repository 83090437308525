import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky to-header"
  }, [_c(VBtn, _vm._b({
    attrs: {
      "color": "grey"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, [_vm._v("mdi-arrow-left")])], 1), _c(VBtn, _vm._b({
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c(VIcon, [_vm._v("mdi-content-save")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Felhasználó")]), _vm.loading ? _c('loading-overlay') : _vm._e(), _c(VRow, [_c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_vm.form.user.apiuser ? _c(VCard, {
    staticClass: "p-1"
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-account")]), _vm._v("Alapadatok")], 1), _c(VCardText, [_c('v-masked-text-field', {
    attrs: {
      "label": "Név",
      "error-messages": _vm.errors.name,
      "mask": _vm.$config.inputMasks.name,
      "filled": ""
    },
    model: {
      value: _vm.form.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "name", $$v);
      },
      expression: "form.user.name"
    }
  }), _c('v-masked-textarea', {
    attrs: {
      "label": "API kulcs",
      "error-messages": _vm.errors.name,
      "mask": _vm.$config.inputMasks.name,
      "filled": "",
      "rows": "1",
      "auto-grow": ""
    },
    model: {
      value: _vm.form.user.apikey,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "apikey", $$v);
      },
      expression: "form.user.apikey"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Lejárat dátuma",
      "readonly": "",
      "filled": ""
    },
    model: {
      value: _vm.form.user.apikey_expire,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "apikey_expire", $$v);
      },
      expression: "form.user.apikey_expire"
    }
  })], 1)], 1) : _c(VCard, {
    staticClass: "p-1"
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-account")]), _vm._v("Alapadatok")], 1), _c(VCardText, [_c('v-masked-text-field', {
    attrs: {
      "label": "Név",
      "error-messages": _vm.errors.name,
      "mask": _vm.$config.inputMasks.name,
      "filled": ""
    },
    model: {
      value: _vm.form.user.name,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "name", $$v);
      },
      expression: "form.user.name"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "Email",
      "error-messages": _vm.errors.email,
      "filled": ""
    },
    model: {
      value: _vm.form.user.email,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "email", $$v);
      },
      expression: "form.user.email"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Jelszó",
      "error-messages": _vm.errors.password,
      "filled": "",
      "mask": _vm.$config.inputMasks.password,
      "append-icon": _vm.showPass ? 'mdi-eye' : 'mdi-eye-off',
      "type": _vm.showPass ? 'text' : 'password',
      "autocomplete": "new-password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showPass = !_vm.showPass;
      }
    },
    model: {
      value: _vm.form.user.password,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "password", $$v);
      },
      expression: "form.user.password"
    }
  }), _c('v-masked-text-field', {
    attrs: {
      "label": "Jelszó Újra",
      "error-messages": _vm.errors.password_,
      "filled": "",
      "mask": _vm.$config.inputMasks.password,
      "append-icon": _vm.showPassAgain ? 'mdi-eye' : 'mdi-eye-off',
      "type": _vm.showPassAgain ? 'text' : 'password',
      "autocomplete": "new-password"
    },
    on: {
      "click:append": function ($event) {
        _vm.showPassAgain = !_vm.showPassAgain;
      }
    },
    model: {
      value: _vm.form.user.password_,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "password_", $$v);
      },
      expression: "form.user.password_"
    }
  })], 1)], 1)], 1), _c(VCol, {
    attrs: {
      "cols": 12,
      "md": 6
    }
  }, [_c(VCard, {
    staticClass: "p-1"
  }, [_c(VCardTitle, [_c(VIcon, {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-key-chain")]), _vm._v("Engedélyek")], 1), _c(VCardText, [_c(VSwitch, {
    attrs: {
      "label": "Csoportképzésnél szűrő"
    },
    model: {
      value: _vm.form.user.filter_element,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "filter_element", $$v);
      },
      expression: "form.user.filter_element"
    }
  }), _c(VSwitch, {
    attrs: {
      "label": "QR kód beállítás fix 24 óra"
    },
    model: {
      value: _vm.form.user.barcode_period_fix,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "barcode_period_fix", $$v);
      },
      expression: "form.user.barcode_period_fix"
    }
  }), _c(VSelect, {
    attrs: {
      "filled": "",
      "label": "QR kód igénylés csoportok",
      "items": _vm.usertype_groups,
      "item-text": "name",
      "item-value": "element_id",
      "multiple": "",
      "chips": ""
    },
    model: {
      value: _vm.form.user.barcode_request_groups,
      callback: function ($$v) {
        _vm.$set(_vm.form.user, "barcode_request_groups", $$v);
      },
      expression: "form.user.barcode_request_groups"
    }
  }), _c(VTreeview, {
    ref: "rightsTree",
    attrs: {
      "items": _vm.form.rights,
      "selection-type": "leaf",
      "selectable": "",
      "return-object": "",
      "open-all": "",
      "selected-color": "primary",
      "shaped": "",
      "transition": "",
      "hoverable": "",
      "multiple-active": "",
      "dense": ""
    },
    on: {
      "input": _vm.rightsInputHandler
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function (_ref) {
        var item = _ref.item,
          open = _ref.open;
        return [_c(VIcon, [_vm._v(" " + _vm._s(item.children ? open ? 'mdi-folder-open-outline' : 'mdi-folder-key-outline' : 'mdi-shield-key-outline') + " ")])];
      }
    }, {
      key: "label",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.name) + " "), _vm.getRightDependencies(item.id) ? _c('span', [_vm._v(" (Felülírja: " + _vm._s(_vm.getRightDependencies(item.id)) + ") ")]) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.selection,
      callback: function ($$v) {
        _vm.selection = $$v;
      },
      expression: "selection"
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }